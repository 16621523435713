
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { THomeWidget } from "apiTypes";
import NewHomePage from "components/Home/new-home-page";
import { TUtilities } from "customHooks/useUtilities";
import fs from "fs/promises";
import { GetStaticProps } from "next";
import path from "path";
import { API_URL } from "../config";
import { getData } from "../utils/getData";
import Gaza from "./gaza";
import Ramadan from "./ramadan";
type Props = {
    data: THomeWidget[];
    quick_actions: TUtilities["quick_actions"];
    isRamadan?: boolean;
    isGaza?: boolean;
    promoVid?: string;
};
function Index({ data, quick_actions, isRamadan, isGaza, promoVid }: Props) {
    // useEffect(() => {
    //   if (typeof window !== "undefined") {
    //     var str = navigator.userAgent;
    //     var i = str.indexOf("Instagram");
    //     const currentUrl = window.location.href;
    //     if (i != -1) {
    //       document.write(
    //         `<a target="_blank" href="${currentUrl}">Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari Proceed to Sfari </a>`
    //       );
    //     }
    //   }
    // }, []);
    if (isRamadan) {
        return <Ramadan />;
    }
    else if (isGaza) {
        return <Gaza />;
    }
    else {
        return (
        // <HomePage data={data} quick_actions={quick_actions} promoVid={promoVid} />
        <NewHomePage data={data} quick_actions={quick_actions} promoVid={promoVid}/>);
    }
}
export default Index;
const getStaticProps: GetStaticProps<Props> = async ({ locale }) => {
    try {
        const utilities: TUtilities = await JSON.parse(await fs.readFile(path.join(process.cwd(), "constants", "utilities.json"), "utf8"));
        const res = await getData<THomeWidget[]>(`${API_URL}/home-widgets`, undefined, {
            locale
        });
        const data: THomeWidget[] = Object.keys(res.data).map((key) => res.data[key]);
        const tempData = data.map((widget) => {
            if (widget.type === "slider") {
                widget.slides = widget.slides.map((slide) => {
                    slide.image = {
                        thumbnails: {
                            "16:9": slide?.image?.thumbnails["16:9"] ?? ""
                        }
                    } as any;
                    return slide;
                });
                return widget;
            }
            else if (widget.type === "target_cards") {
                widget.targets = widget.targets.map((target) => {
                    target.contents.details = "";
                    target.contents.raw_details = "";
                    target.contents.description =
                        target.contents.description?.slice(0, 100) || "";
                    delete (target as any).content;
                    // delete target.funding_progress_bar;
                    delete target.required_amount;
                    delete (target as any).preview_images;
                    target.preview_image = {
                        thumbnails: {
                            "1:1": target?.preview_image?.thumbnails["1:1"] ?? ""
                        }
                    } as any;
                    return target;
                });
                return widget;
            }
            else {
                widget.blog_posts = widget.blog_posts.map((blog) => {
                    blog.contents.body = "";
                    blog.contents.raw_body = blog.contents.raw_body?.slice(0, 100) || "";
                    blog.preview_image = {
                        thumbnails: {
                            "1:1": blog?.preview_image?.thumbnails["1:1"] ?? ""
                        }
                    } as any;
                    return blog;
                });
                return widget;
            }
        });
        return {
            props: {
                data: tempData,
                quick_actions: utilities?.quick_actions,
                promoVid: "https://youtube.com/embed/vZvqN7H_EB0",
                // isRamadan: utilities?.isRamadan,
                isRamadan: false,
                isGaza: false
            },
            revalidate: 300
        };
    }
    catch (error) {
        return {
            props: { data: [], quick_actions: [], isRamadan: false },
            revalidate: 10
        };
    }
};

    async function __Next_Translate__getStaticProps__193bd6106af__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__193bd6106af__ as getStaticProps }
  