
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    // main Layout
import Router from "next/router";
import Layout from "../layout/Layout";
import "../styles/custom-bootstrap.css";
import "../styles/indian-styles.css";
// styles from Around theme
import "../styles/main.rtl.css";
import "../styles/theme.css";
import "react-toastify/dist/ReactToastify.css";
// react slick
import "slick-carousel/slick/slick.css";
import "../styles/slick-theme.css";
//styles of nprogress
import "nprogress/nprogress.css";
// simple bar
import "react-tabs-scrollable/dist/rts.css";
import "simplebar/dist/simplebar.min.css";
// react circular progressbar
import "react-circular-progressbar/dist/styles.css";
//  main styles
import "../styles/main.css";
//nprogress module
import NProgress from "nprogress";
// next intl provider
// theme context provider
import ModeProvider from "../context/ModeContext";
// default layoyut
import DefaultLayout from "../layout/DefaultLayout";
// my custom MUI config
import "@smastrom/react-rating/style.css";
// main context provider
// auth context provider
import { GoogleTagManager } from "@next/third-parties/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { SpeedInsights } from "@vercel/speed-insights/next";
import Footer from "components/Footer";
import DonateModal from "components/ReuseableCompos/DonateModal";
import ClarityScript from "config/clarity";
import { Mixpanel } from "config/mixpanel";
import TwitterPixel from "config/twitterPixel";
import CrispModalProvider from "context/CrispContext";
import FirebaseTrackingProvider from "context/FirebaseContext";
import { InAppMsgModalProvider } from "context/InAppMsgModalContext";
import { ReviewModalProvider } from "context/ReviewModalContext";
import { ThumbmarkProvider } from "context/ThumbmarkContext";
import { useIsomorphicLayoutEffect } from "customHooks/useIsomorphicLayoutEffect";
import { m } from "framer-motion";
import { DefaultSeo } from "next-seo";
import useTranslation from "next-translate/useTranslation";
import { Inter } from "next/font/google";
import localFont from "next/font/local";
import { useEffect } from "react";
import { SWRConfig } from "swr";
import BodyLock from "utils/bodyLock";
import { gtmPageView } from "utils/gtm";
import ErrorBoundary from "../components/ErrorBoundary";
import { AuthProvider } from "../context/AuthContext";
import { AuthModalProvider } from "../context/AuthModalContext";
import CheckoutHandler from "../context/CheckoutHandler";
import FramerMotionProvider from "../context/FramerMotionProvider";
import IntlProvider from "../context/IntlProvider";
import PayPalProvider from "../context/PayPalProvider";
import { PreferenceProvider } from "../context/PreferencesContext";
import StripeProvider from "../context/StripeProvider";
import CheckVerificationWrapper from "../layout/CheckVerificationWrapper";
import * as fbq from "../utils/facebookPixel"; // Adjust path if different
import { fetcher } from "../utils/getData";
const bahij = localFont({
    src: [
        {
            path: "../fonts/bahij/Bahij_Black.ttf",
            weight: "900",
            style: "normal"
        },
        {
            path: "../fonts/bahij/Bahij_Bold.ttf",
            weight: "700",
            style: "normal"
        },
        {
            path: "../fonts/bahij/Bahij_ExtraBold.ttf",
            weight: "800",
            style: "normal"
        },
        {
            path: "../fonts/bahij/Bahij_ExtraLight.ttf",
            weight: "200",
            style: "normal"
        },
        {
            path: "../fonts/bahij/Bahij_Light.ttf",
            weight: "300",
            style: "normal"
        },
        {
            path: "../fonts/bahij/Bahij_Plain.ttf",
            weight: "500",
            style: "normal"
        },
        {
            path: "../fonts/bahij/Bahij_SemiLight.ttf",
            weight: "400",
            style: "normal"
        },
    ],
    variable: "--font-bahij"
});
const inter = Inter({
    weight: ["300", "400", "500", "600", "700"],
    subsets: ["latin"]
});
const jfFlatRegular = localFont({
    src: [
        {
            path: "../public/fonts/JF-Flat-regular.ttf",
            weight: "500",
            style: "normal"
        },
    ]
});
// showing upper loading bar on routing change
//Binding events.
NProgress.configure({
    showSpinner: false
});
Router.events.on("routeChangeStart", () => {
    BodyLock.unlock();
    NProgress.start();
});
Router.events.on("routeChangeComplete", () => {
    BodyLock.unlock();
    NProgress.done();
});
Router.events.on("routeChangeError", () => {
    BodyLock.unlock();
    NProgress.done();
});
const spring = {
    duration: 0.5
};
function MyApp({ Component, pageProps, router }) {
    const CustomLayout = Component.Layout || DefaultLayout;
    useIsomorphicLayoutEffect(() => {
        const props = {
            page_title: pageProps.slug || null
        };
        gtmPageView(props);
    }, [pageProps]);
    useEffect(() => {
        if (typeof window !== "undefined") {
            const handleRouteChange = () => {
                fbq.fbPageView();
                console.log("counted page view");
            };
            router.events.on("routeChangeComplete", handleRouteChange);
            return () => {
                router.events.off("routeChangeComplete", handleRouteChange);
            };
        }
    }, [router.events]);
    const { t, lang } = useTranslation("common");
    const isDev = process.env.NODE_ENV === "development";
    return (<>
      <DefaultSeo openGraph={{
            type: "website",
            locale: lang,
            url: `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`,
            site_name: t("molham_volunteering_team")
        }} title={t("molham_volunteering_team")} description={t("molham_website_description")} twitter={{
            handle: "@molhamteam",
            site: "@molhamteam",
            cardType: "summary_large_image"
        }}/>
      <Mixpanel />

      <TwitterPixel />
      {!isDev && <ClarityScript />}
      <SWRConfig value={{
            fetcher
        }}>
        <style jsx global>{`
          :root {
            --bs-font-sans-serif: ${inter.style.fontFamily},
              ${bahij.style.fontFamily}, Arial;
            --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
              "Liberation Mono", "Courier New", monospace;
            --font-bahij: ${bahij.style.fontFamily};
          }
          html {
            font-family: ${bahij.style.fontFamily}, "Segoe UI", Tahoma, Geneva,
              Verdana, Arial;
          }
          body {
            font-family: ${bahij.style.fontFamily}, Arial !important;
          }
          body.rtl {
            font-family: ${inter.style.fontFamily}, ${bahij.style.fontFamily},
              ${jfFlatRegular.style.fontFamily}, Arial;
          }
          .rtl .Toastify__toast-body {
            font-family: ${inter.style.fontFamily}, ${bahij.style.fontFamily},
              ${jfFlatRegular.style.fontFamily}, Arial;
          }
        `}</style>
        <ThumbmarkProvider>
          <GoogleOAuthProvider clientId="1010928718503-7s64vifqb6s708od2dlagb31s932g1cf.apps.googleusercontent.com">
            <AuthModalProvider>
              <AuthProvider>
                <ModeProvider>
                  <IntlProvider>
                    <PreferenceProvider>
                      <InAppMsgModalProvider>
                        <ReviewModalProvider>
                          <PayPalProvider>
                            <StripeProvider>
                              <CrispModalProvider>
                                <CheckoutHandler>
                                  <FramerMotionProvider>
                                    <FirebaseTrackingProvider>
                                      <Layout mainClassname={bahij.className}>
                                        <CustomLayout {...pageProps}>
                                          <m.div transition={spring} key={router.pathname} initial={{
            opacity: 0
        }} animate={{
            opacity: 1,
            transitionEnd: {
                display: "block"
            }
        }} exit={{
            // opacity: 0,
            transitionEnd: {
                display: "none"
            }
        }}>
                                            <ErrorBoundary>
                                              <CheckVerificationWrapper {...pageProps}>
                                                <div hidden>
                                                  Molham Volunteering Team
                                                </div>
                                                <Component {...pageProps}/>
                                                <GoogleTagManager gtmId="GTM-M9LJQ38L"/>

                                                <SpeedInsights />
                                                <DonateModal />
                                              </CheckVerificationWrapper>
                                            </ErrorBoundary>
                                          </m.div>
                                        </CustomLayout>
                                        <Footer />
                                      </Layout>
                                    </FirebaseTrackingProvider>
                                  </FramerMotionProvider>
                                </CheckoutHandler>
                              </CrispModalProvider>
                            </StripeProvider>
                          </PayPalProvider>
                        </ReviewModalProvider>
                      </InAppMsgModalProvider>
                    </PreferenceProvider>
                  </IntlProvider>
                </ModeProvider>
              </AuthProvider>
            </AuthModalProvider>
          </GoogleOAuthProvider>
        </ThumbmarkProvider>
      </SWRConfig>
    </>);
}
const __Next_Translate__Page__194a29e9d72__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__194a29e9d72__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  